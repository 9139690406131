import React, { useEffect, useRef, useState } from 'react';

const BackgroundAudio = ({ url }) => {
    const audioRef = useRef(new Audio(url));
    const [isPlaying, setIsPlaying] = useState(false);

    const toggleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch(error => console.error('Error playing audio:', error));
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        audioRef.current.loop = true;

        if (isPlaying) {
            audioRef.current.play().catch(error => console.error('Error playing audio:', error));
        } else {
            audioRef.current.pause();
        }

        return () => {
            audioRef.current.pause();
        };
    }, [isPlaying, url]);

    return (
        <div style={{ backgroundColor: 'green', padding: '5px', borderRadius: '5px', display: 'inline-block' }}>
            <button
                onClick={toggleAudio}
                style={{ backgroundColor: 'pink', border: 'none', borderRadius: '3px', padding: '5px 10px' }}
            >
                {isPlaying ? 'Stop Audio' : 'Play Audio'}
            </button>
        </div>
    );
};

export default BackgroundAudio;