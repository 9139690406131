import { useEffect, useState } from 'react';
import './App.css';
import BackgroundAudio from './BackgroundAudio';
function App() {
  // const audio = useRef();
  const [play, setPlay] = useState(false)

  useEffect(() => {
    console.log('-------')
    setPlay(true)
  }, [])
  useEffect(() => {
    console.log('=========', play)
  }, [play])
  return (
    <div className="App">
      <header className="pink-background header main-color mt-14">
        <a href="https://twitter.com/tickerincel" target='blank'><span className="main-color">TWITTER</span></a>
        <a href="https://t.me/tickerINCEL" target='blank'><span className="main-color">TELEGRAM</span></a>
        <a href="https://coinhall.org/injective/inj1gar4t552vxllavj5s2k09wah4n7s58zxjsxppp" target='blank'><span className="main-color">COINHALL</span></a>
      </header>
      <div className="px-5 flex mt-20 flex-col md:flex-row">
                <div className='w-full md:w-[45%] flex flex-col items-center px-10 md:px-48 pt-20'>
                    {play && <BackgroundAudio url="/music/rich.mp3" />}
                    <p className="text-white text-3xl ink-font pt-10">we found the</p>
                    <p className="inside-font text-5xl main-color mt-3">$INCEL</p>
                    <p className='main-color p-10 text-2xl text-center ink-font'>The generational once in a lifetime trade of this crypto bull cycle: “INCEL” (Injective+ Celestia) represents the best possible investment available in the market. Modular Money and an Inspirationally Injective network combine to provide what dreams are made of.</p>
                </div>
                <div className="green-background w-full md:w-[55%] flex justify-center px-16 md:px-32 py-8 md:py-16">
                    <img src="/images/first.png" className="w-[100%]" alt="image" />
                </div>
            </div>
      <div className="pink-color inside-font text-7xl py-16 text-center">
        <p>WHAT ARE YOU DOING HERE?</p>
      </div>
      <div className="flex flex-col md:flex-row mb-5">
        <div className='green-background w-full md:w-1/2 flex'>
          <div className="flex justify-center w-1/5">
            <p className="text-bottom-to-top font-black text-5xl md:text-8xl text-center inside-font">YOU $INCEL?</p>
          </div>
          <div className='pt-5 pr-10 pb-8 w-4/5'>
            <img src="/images/second.png" className="w-[100%]" />
          </div>
        </div>
        <div className="pink-background w-full md:w-1/2 flex">
          <div className='w-4/5 pt-5 pl-10 pb-8'>
            <img src="/images/third.png" className="w-[100%]" />
          </div>
          <div className="w-1/5 flex justify-center">
            <p className='text-top-to-bottom font-black text-5xl md:text-8xl text-center inside-font'>YOU $INCEL?</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pt-28 flex justify-center flex-col items-center px-12">
          <p className='main-color text-6xl font-bold inside-font'>YOU NOOB!</p>
          <img src="/images/fourth.png" className="py-5 w-4/5" alt="fourth-image" />
          <p className="pink-color w-3/5 text-center text-2xl ink-font">Studies show 27% of young men aren’t having sex anymore. We here at InjectiveCelestiaNoFapLadyBoy420Inu are the 27%. We don’t have sex. We edge but we always retain. However We aren’t immune to getting blood flow in the pelvic region when we lay eyes on thai ladyboys. Bull Trapper + HentaiAvenger66 are the pinnacle representation of what it means to be an $INCEL member.</p>
        </div>
        <div className="w-full md:w-1/2">
          <img src="/images/fifth.png" alt="fifth-image" className="w-full" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 flex green-background">
          <div className='w-4/5 py-10'>
            <img src="/images/sixth.png" alt="sixth-image" className="w-full" />
          </div>
          <div className="w-1/5 flex justify-center">
            <p className='text-bottom-to-top font-black text-5xl md:text-8xl text-center inside-font'>
              GOING SOMEWHERE?
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-col">
          <div className="w-full">
            <img src="/images/seventh.png" alt="seventh-image" className="w-full px-10 pt-10" />
          </div>
          <div className="w-full">
            <img src="/images/eighth.png" alt="seventh-image" className="w-full px-10 pt-10" />
          </div>
        </div>
      </div>
      <div className="mt-32 text-center flex flex-col items-center justify-center">
        <div className="w-3/5">
          <p className="main-color text-8xl inside-font">LET'S TRADE</p>
          <p className="blue-color pt-10 ink-font">100m Supply 0 Tax The first REAL memecoin on Injective chain Fuck your dogs, Fuck your founders pets, INCEL.</p>
        </div>
      </div>
      <div className="flex pt-20">
        <div className="w-1/5 text-center flex flex-col pt-96">
          <p className="main-color text-5xl inside-font">I</p>
          <p className="main-color text-5xl inside-font pt-5">HAVE</p>
        </div>
        <div className="w-3/5 green-background">
          <img src="/images/nineth.png" alt="nineth-image" className="w-full px-10 pt-20 pb-96" />

        </div>
        <div className="w-1/5 text-center  pt-96">
          <p className="main-color text-5xl inside-font">$INCEL</p>
        </div>
      </div>
      <footer className="pink-background header main-color mt-14 top-[-185px]">
        <a href="https://twitter.com/tickerincel" target='blank'><span className="main-color">TWITTER</span></a>
        <a href="https://t.me/tickerINCEL" target='blank'><span className="main-color">TELEGRAM</span></a>
        <a href="https://coinhall.org/injective/inj1gar4t552vxllavj5s2k09wah4n7s58zxjsxppp" target='blank'><span className="main-color">COINHALL</span></a>
      </footer>
      {
  
      }

    </div>
  );
}

export default App;